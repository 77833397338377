/**
 * Actual entry point for login page JS, referenced by build system.
 *
 * This includes polyfills, automatically-run startup code, and anything
 * else that automated tests don't want to deal with. Tests should import
 * start.ts instead.
 */

// Polyfills
import "promise-polyfill/src/polyfill";
import "whatwg-fetch";
